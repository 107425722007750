import EventBus from '@/util/EventBus.js'
import MultimediaService from '@/services/multimedia.service.js'
import WhatsappTemplateService from '@/services/whatsapp-template.service'
import { WhatsappTemplateStatus } from '../../../classes/WhatsappTemplateStatus'
import RoutesService from '@/services/whatsapp/routes.service'
import { LandingFormats } from '../Common/Formats/LandingFormats'

export default {
  name: 'HeaderMultimedia',
  props: {
    multimediaDirectories: { type: Object, require: true },
    multimediaPreview: { type: Object, required: true },
    fileQuota: { type: Object, require: true },
    currentDirectory: { type: Object, require: true },
    rootDirectories: { type: Object, require: true },
    origin: { type: String, require: true },
    pixabayResult: { type: Object, required: true },
    sourceParams: { type: Object, required: true },
  },
  data () {
    return {
      viewGrid: true,
      viewTable: false,
      search: '',
      lastSearch: null,
      pathInitial: null,
      whatsappTemplateCategories: [],
      whatsappTemplateStatuses: [],
      pixaBayDialog: false,
      debouncedInput: '',
      timeout: null,
      whatsappTemplateWabas: [],
    }
  },
  methods: {
    getWabas () {
      RoutesService.getCurrentUserAllRoutes()
      .then((routes) => {
        routes.forEach(route => {
          this.whatsappTemplateWabas.push({
            id: null,
            name: this.$t('Todos'),
          })
          this.whatsappTemplateWabas.push({
            id: route.waba_id,
            name: route.display_name + ' - ' + route.waba_id,
          })
        })
      })
    },
    clearViewContent () {
      this.selectMultipleItems('none')
      EventBus.$emit('ViewGrid:clearSelectedItem')
      this.multimediaPreview.show = false
      EventBus.$emit('MutimeidaManager:hideMultimediaPreview')
    },

    setSearchSource (source) {
      if (this.sourceParams.source !== source) {
          this.pixabayResult.hits = []
          this.pixabayResult.selected = []
          this.pixabayResult.simpleHitSelected = null
          this.multimediaPreview.show = false
          this.search = ''
          this.lastSearch = null
          EventBus.$emit('MutimeidaManager:hideMultimediaPreview')
      }
      this.sourceParams.source = source
      if (source === 'pixabay') {
        document.getElementById('MultimediaSearchInput').focus()
      }
    },
    close () {
      EventBus.$emit('closeMultimedia')
    },
    newWhatsappTemplate () {
      EventBus.$emit('newCampaign', 'whatsapp')
      this.close()
    },
    uploadFile () {
      this.multimediaDirectories.uploadFile.show = true
    },
    createDirectory () {
      this.multimediaDirectories.createDirectory.show = true
      this.multimediaDirectories.createDirectory.path = this.currentDirectory.path
    },
    deletedTrash () {
      EventBus.$emit('deletedTrashMultimedia')
    },
    deletedSelectedMultipleItems () {
      this.multimediaDirectories.selectedMultiple.show = true
    },
    changeView () {
      this.viewGrid = !this.viewGrid
      this.viewTable = !this.viewTable
      EventBus.$emit('changeViewContentMultimedia')
    },
    goToPreviousDirectory (path) {
      if (this.currentDirectory.path === 'buscar') {
        this.search = ''
        this.lastSearch = null
        const splitSearch = this.currentDirectory.initialPath.split('/')
        let searchDirectory = this.multimediaDirectories.directories[splitSearch[0]]
        for (let i = 1; i < splitSearch.length; i++) {
          searchDirectory = searchDirectory.directories[splitSearch[i]]
        }
        this.multimediaPreview.reset()
        this.currentDirectory.loadData(searchDirectory)
        this.multimediaDirectories.resetDeleteSelectedMultiple()
        return
      }

      if (this.sourceParams.source === 'pixabay') {
        this.setSearchSource('library')
        EventBus.$emit('ListDirectory:selectRootDirectory', 'imagenes')
        return
      }
      const split = path.split('/')
      let directory = this.multimediaDirectories.directories[split[0]]

      if (!directory) {
        directory = this.multimediaDirectories.directories[this.currentDirectory.initialPath]
      } else {
        for (let i = 1; i < split.length - 1; i++) {
          directory = directory.directories[split[i]]
        }
      }

      if (directory.path === 'papelera') {
        this.multimediaDirectories.hideFixedMultimedia()
      }

      const splitDirectory = directory.path.split('/')
      let directoryCurrent = this.multimediaDirectories.directories[splitDirectory[0]]
      for (let i = 1; i < split.length; i++) {
        directoryCurrent = directoryCurrent.directories[split[i]]
      }
      this.multimediaPreview.reset()
      this.currentDirectory.loadData(directory)
      this.multimediaDirectories.resetDeleteSelectedMultiple()
    },
    searchFile () {
      if (this.search.trim() === '') {
        return
      }

      this.lastSearch = this.search

      if (this.sourceParams.source === 'pixabay') {
        this.multimediaPreview.show = false
        EventBus.$emit('makePixaBaySearch', this.search)
        return
      }

      if (this.currentDirectory.path !== 'buscar') {
        this.pathInitial = this.currentDirectory.path
      }

      if (this.search === '') {
        this.selectCurrentDirectory(this.pathInitial)
        return
      }

      const params = {
        path: this.pathInitial,
        search: this.search,
      }
      MultimediaService.search(params).then(
        (response) => {
          this.multimediaPreview.reset()
          const multimediaContent = document.querySelector('#multimediaContent')
          const multimediaPreview = document.querySelector('#multimediaPreview')
          this.currentDirectory.loadData(response.search)
          this.multimediaDirectories.resetDeleteSelectedMultiple()

          setTimeout(() => {
            if (this.currentDirectory.path === 'buscar' && (
              this.currentDirectory.initialPath === 'imagenes' ||
              this.currentDirectory.initialPath === 'videos' ||
              this.currentDirectory.initialPath === 'whatsapp' ||
              this.currentDirectory.initialPath === 'audios' ||
              this.currentDirectory.initialPath === 'adjuntos')
            ) {
              multimediaPreview.classList.add('col-md-3', 'multimedia__preview-vertical-container')
              multimediaContent.classList.add('col-md-9', 'multimedia__content-vertical-data')
            } else {
              multimediaContent.classList.add('col-md-12', 'multimedia__content-vertical-data')
            }
          }, 100)
        },
        (error) => {
          EventBus.$emit('showAlert', 'warning', error)
        },
      )
    },
    selectCurrentDirectory (currentPath) {
      if (currentPath === 'papelera') {
        this.multimediaDirectories.hideFixedMultimedia()
      }

      const split = currentPath.split('/')
      let directory = this.multimediaDirectories.directories[split[0]]
      for (let i = 1; i < split.length; i++) {
        directory = directory.directories[split[i]]
      }
      this.multimediaPreview.reset()
      this.currentDirectory.loadData(directory)
      this.multimediaDirectories.resetDeleteSelectedMultiple()
    },
    setRootDirectoryName (name) {
      if (['voz', 'audios', 'adjuntos', 'formularios', 'papelera', 'buscar', 'videos', 'whatsapp'].indexOf(name.toLowerCase()) >= 0) {
        return this.$t(this.capitalizaice(name).replace(/-/g, ' '))
      }
      if (name === 'csv') {
        return name.toUpperCase()
      }
      if (name === 'landings') {
        return 'Landing page'
      }
      if (name === 'imagenes') {
        return this.$t('Imágenes')
      }

      return name
    },
    capitalizaice (name) {
      return (
        name.charAt(0).toLocaleUpperCase() + name.substr(1).toLowerCase()
      )
    },
    viewCreateFolder () {
      if (this.rootDirectory === 'papelera') {
        return false
      }

      if (this.rootDirectory === 'buscar') {
        return false
      }

      return true
    },
    // eslint-disable-next-line complexity
    selectMultipleItems (action) {
      if (action === 'all') {
        if (this.sourceParams.source === 'library') {
          const user = JSON.parse(localStorage.getItem('user'))
          // eslint-disable-next-line max-depth
          for (const file in this.currentDirectory.files) {
            this.multimediaDirectories.selectedMultiple.items.push({
              name: this.currentDirectory.files[file].name,
              nameTemplate: this.currentDirectory.files[file].nameTemplate,
              path: this.currentDirectory.files[file].path,
              fileFormat: this.currentDirectory.files[file].fileFormat,
              type: 'file',
              extension: this.currentDirectory.files[file].extension,
              size: this.currentDirectory.files[file].size,
              date: this.currentDirectory.files[file].date,
              url: this.currentDirectory.files[file].url + '&token=' + user.token,
              fileType: this.currentDirectory.files[file].fileType,
              csv: this.currentDirectory.files[file].csv,
              fileId: this.currentDirectory.files[file].fileId,
              preview: this.currentDirectory.files[file].preview,
            })
          }

          // eslint-disable-next-line max-depth
          for (const directory in this.currentDirectory.directories) {
            this.multimediaDirectories.selectedMultiple.items.push({
              name: directory,
              path: this.currentDirectory.directories[directory].path,
              type: 'directory',
              fileType: this.currentDirectory.directories[directory].directoryType,
              size: this.currentDirectory.directories[directory].size,
              date: this.currentDirectory.directories[directory].date,
              directories: this.currentDirectory.directories[directory].directories,
            })
          }
        }

        if (this.sourceParams.source === 'pixabay') {
          this.pixabayResult.selected = JSON.parse(JSON.stringify(this.pixabayResult.hits))
        }
      } else if (action === 'none') {
        if (this.sourceParams.source === 'library') {
          this.multimediaDirectories.selectedMultiple.items = []
        }

        if (this.sourceParams.source === 'pixabay') {
          this.pixabayResult.selected = []
        }
        this.multimediaPreview.reset()
        EventBus.$emit('ViewGrid:clearSelectedItem')
      }
    },
    getCategories () {
      WhatsappTemplateService.getCategories()
        .then(response => {
          this.whatsappTemplateCategories = [
            {
              id: null,
              name: this.$t('Todas'),
            },
            ...response.data,
          ]
        })
    },
    getStatuses () {
      WhatsappTemplateService.getStatuses()
        .then(response => {
          this.whatsappTemplateStatuses = [
            {
              id: null,
              name: this.$t('Todos'),
            },
            ...response.data.filter(status => [
              WhatsappTemplateStatus.DRAFT,
              WhatsappTemplateStatus.SENT_TO_VALIDATE,
              WhatsappTemplateStatus.APPROVED,
              WhatsappTemplateStatus.REJECTED,
              WhatsappTemplateStatus.INCORRECT_FORMAT,
              WhatsappTemplateStatus.PAUSED,
            ].includes(status.id)),
          ]
        })
    },
    originMultimedia () {
      if (this.origin === 'whatsapp') {
        this.multimediaDirectories.filter.whatsappTemplateStatus = WhatsappTemplateStatus.APPROVED
      }
    },
  },
  computed: {
    translatedUsedWhatsappTemplateLanguages () {
      return this.usedWhatsappTemplateLanguages.map(l => {
        l.name = this.$t(l.name)
        return l
      })
    },
    inImagesOrVideos () {
      // 'videos'
      return ['imagenes'].includes(this.currentDirectory.path)
    },
    rootDirectory: function () {
      return this.currentDirectory.path.split('/')[0]
    },
    isEmptyDir: function () {
      return this.currentDirectory.size === 0
    },
    landingFormatsTranslated: function () {
      return [
        {
          id: null,
          name: this.$t('Todas'),
        },
        ...LandingFormats.formats,
      ]
    },
    templateCategoriesTranslated: function () {
      return this.whatsappTemplateCategories.map((category) => {
        category.name = this.$t(category.name).toString()
        return category
      })
    },
    templateStatusesTranslated: function () {
      return this.whatsappTemplateStatuses.map((status) => {
        const name = status.id !== null
          ? 'WHATSAPP_STATUS_' + status.name
          : status.name
        status.name = this.$t(name).toString()
        return status
      })
    },
    usedWhatsappTemplateLanguages () {
      let languages = this.multimediaDirectories.directories.whatsapp.files?.map((element) => {
        return element.preview?.template_whatsapp_language
      })

      const visibleLanguages = [
        ...[
          {
            id: null,
            name: this.$t('Todos'),
          },
        ],
      ]
      if (!languages) {
        return visibleLanguages
      }

      languages = languages.filter(elements => { return elements !== null && elements !== undefined })

      return [
        ...visibleLanguages,
        ...new Map(languages.map((item) => [item.id, item])).values(),
      ]
    },
    canDeleted () {
      let status = false
      if (this.multimediaPreview.show || this.multimediaDirectories.selectedMultiple.items.length > 0) {
        status = true
      }

      return status
    },
  },
  created () {
    this.getCategories()
    this.getStatuses()
    this.getWabas()
  },
  mounted () {
    this.search = ''
    this.lastSearch = null
    this.originMultimedia()
    EventBus.$on('MultimediaManager:setEmptySearch', () => {
      this.multimediaPreview.show = false
    })
    EventBus.$on('MultimediaManager::setWabaId', (wabaId) => {
      this.multimediaDirectories.filter.whatsappTemplateWabaId = wabaId
    })
    EventBus.$on('multimediaManager::search', () => {
      this.searchFile()
    })
    EventBus.$on('multimediaManager::searchClear', () => {
      this.search = ''
      this.lastSearch = null
    })
  },
}
